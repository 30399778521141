body, html {
    background-image: url(../images/bg-wave-greyscale.svg), linear-gradient(45deg, rgba(251,251,251,1) 0%, rgba(203,203,203,1) 100%);

    &.team-member {
        background-image: url(../images/bg-wave-greyscale.svg), linear-gradient(45deg, rgba(251,251,251,1) 0%, rgba(203,203,203,1) 100%);
    }

}

.dialog-off-canvas-main-canvas {
    filter: grayscale(100%);
}

.ui-dialog {
    filter: grayscale(100%);
}